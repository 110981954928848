import React, { Component } from "react";

class CommonProgressBar extends Component {
  
  componentDidMount() {
    //Setting progress bar values..
    let totalbars = document.querySelectorAll(".progress-bar").length;

    if(totalbars > 0) {
      for(let c=0; c<totalbars; c++) {
          let ele = document.querySelectorAll(".progress-bar")[c];
          let rate = ele.getAttribute('aria-valuenow');

          ele.style.width = rate + "%";
          ele.style['max-width']= rate + "%";
          ele.innerHTML = rate + "%";
      }
    }
  }

  render() {
    const { score, counter } = this.props;

    return (
        <div className="progress static-centre-progress">
            <div id={'progress-bar-' + counter} className="progress-bar progress-bar-striped" role="progressbar" aria-valuenow={score} aria-valuemin="0" aria-valuemax="100">
              <span className="title"></span>
            </div>
        </div>
    );
  }
}

export default CommonProgressBar;