import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Results from "./containers/Results";

class Routes extends React.Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/results" component={Results} />
        </Switch>
      </div>
    );
  }
}

export default Routes;
