import React from "react";

const PassRateFiltered = ({ label, rate }) => (

    <tr>
        <td>{label}</td>
        <td>{rate}</td>
    </tr>

);

export default PassRateFiltered;