import React from "react";
import parse from "html-react-parser";
import CommonProgressBar from "./CommonProgressBar";

class OutsideFrameContent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { jsondata } = this.props;
  
    return (
      <>
      {jsondata &&
        <div className="spacer">
            <div className="out-fr-heading-txt">{parse(jsondata.outside_frame_content.heading_txt)}</div>
            <div className="out-fr-first-txt">{parse(jsondata.outside_frame_content.first_p_txt)}</div>
            <div className="out-fr-first-txt1">{parse(jsondata.outside_frame_content.first_p1_txt)}</div>
            
            <div className="out-fr-practice-info-wrapper">
              {jsondata.outside_frame_content.practice_data.map((obj, index) => (
                <div className="out-fr-practice-info-content" key={index}>
                  <CommonProgressBar score={obj.rate} counter={index++} />
                  <div>{parse(obj.info)}</div>
                </div>
              ))}
            </div>

          <div className="out-fr-second-txt1">{parse(jsondata.outside_frame_content.second_p1_txt)}</div>
          <div className="out-fr-third-txt">{parse(jsondata.outside_frame_content.third_p_txt)}</div>
          <div className="out-fr-fourth-txt">{parse(jsondata.outside_frame_content.fourth_p_txt)}</div>
          <div className="out-fr-fifth-txt">{parse(jsondata.outside_frame_content.fifth_p_txt)}</div>
          <div className="out-fr-sign-now"><a href="https://www.wearemarmalade.co.uk/win" target="_blank"  rel="noopener noreferrer">{jsondata.outside_frame_content.sign_now_txt}</a></div>

          <div className="out-fr-learner-wrapper">
            <div>{parse(jsondata.outside_frame_content.learner_insurance.label)}</div><br/>
            <div className="out-fr-learner-wrapper-btn-div" >
              <a href="https://www.wearemarmalade.co.uk/learner-driver-insurance" target="_blank"  rel="noopener noreferrer">{jsondata.outside_frame_content.learner_insurance.btn_txt}</a>
            </div>
          </div>
        </div>
      }
      </>
    );
  }
}

export default OutsideFrameContent;
