import React from "react";
import parse from 'html-react-parser';

const FrameFooter = ({ jsonStaticData }) => (
    
    <section className="frame-footer-text">
        {jsonStaticData && jsonStaticData.frame_footer_txt && 
            <p>
                {parse(jsonStaticData.frame_footer_txt)}
            </p>
        }
    </section>

);

export default FrameFooter;