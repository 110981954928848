function filterByAge( selected_age, age_data ) {
    let agewise_result = [], obj = {};
    let filteredObj = age_data.filter(element => element.age === selected_age);

    //Age label as per selected age..
    obj["age_label"] = "UK average pass rate for your age: " + selected_age;

    if(selected_age > 15 && selected_age < 26) {
        obj["age_rate"] = (filteredObj[0]['2017-2018'] * 100).toFixed(2);
    }
    else {
        let sum = 0, data_sum = 0, min_range = 0, max_range = 0;
        if(selected_age > 25) {
            min_range = 26; max_range = 62;
        }

        let grouped_data = age_data.filter(element => element.age > min_range && element.age < max_range)
            .map(obj => {
                sum = sum + parseFloat(obj['2017-2018'])
                data_sum = sum;
                return data_sum;
            });
            
        let avg = data_sum / grouped_data.length;
        obj["age_rate"] = (avg * 100).toFixed(2);
    }

    //Average pass rate..
    obj["avg_label"] = age_data[age_data.length-1]['2007-2008'];
    obj["avg_rate"] = (age_data[age_data.length-1]['ten-year-average'] * 100).toFixed(2);

    agewise_result.push(obj);

    // console.log("agewise_result");
    // console.log(JSON.stringify(agewise_result));
    return agewise_result;
}

function filterByMonth( selected_month, selected_county_result ) {
    var month_arr = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
    let mnth_index = month_arr.indexOf(selected_month);
    let mnth_str = "";
    let monthwise_result = [], mon = [];

    //Array formation..
    //Last two months selected..
    if(month_arr.indexOf(selected_month) === 0) {
        mon.push(month_arr[month_arr.length-2]);
        mon.push(month_arr[month_arr.length-1]);
        mon.push(month_arr[mnth_index]);
    }
    else if(month_arr.indexOf(selected_month) === 1) {
        mon.push(month_arr[month_arr.length-1]);
        mon.push(month_arr[0]);
        mon.push(month_arr[mnth_index]);
    }
    else {
        for(let k=mnth_index; k>0; k--) {
            if(mon.length < 3)
                mon.push(month_arr[k]);
        }

        mon.reverse();
    }

    //Coming two months selected..
    if(month_arr.indexOf(selected_month) === 9) {
        mon.push(month_arr[mnth_index+1]);
        mon.push(month_arr[mnth_index+2]);
        mon.push(month_arr[0]);
    }
    else if(month_arr.indexOf(selected_month) === 10) {
        mon.push(month_arr[mnth_index+1]);
        mon.push(month_arr[0]);
        mon.push(month_arr[1]);
    }
    else if(month_arr.indexOf(selected_month) === 11) {
        mon.push(month_arr[0]);
        mon.push(month_arr[1]);
        mon.push(month_arr[2]);
    }
    else {
        for(let m=mnth_index+1; m<mnth_index+4; m++) {
            mon.push(month_arr[m]);
        }
    }

    //Form json to display data..
    for(let y=0; y<mon.length; y++) {
        mnth_str = mon[y].substr(0, 3).toLowerCase() + "_rate";
        let ratestr;
        if(selected_county_result[0]) {
            if(parseFloat(selected_county_result[0][mnth_str]) === 0) {
                ratestr = "N/A";
            } 
            else {
                ratestr = (selected_county_result[0][mnth_str] * 100).toFixed(2) + "%";
            }
        }
        let obj = {
            "label": mon[y],
            "rate": ratestr
        };

        monthwise_result.push(obj);    
    }

    // console.log("monthwise_result");
    // console.log(JSON.stringify(monthwise_result));
    return monthwise_result;
}

//Calculate distance between alt/long..
function distance(lat1, lon1, lat2, lon2, unit) {
    var radlat1 = Math.PI * lat1/180;
    var radlat2 = Math.PI * lat2/180;
    var theta = lon1-lon2;
    var radtheta = Math.PI * theta/180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
        dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180/Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") { dist = dist * 1.609344; }
    if (unit === "N") { dist = dist * 0.8684; }
    return dist;
}

function filterByCounty( county_data, selected_county_result, selected_month ) {
    let countywise_result = [];
    let mshortname;
    if(selected_month)
        mshortname = selected_month.substr(0, 3).toLowerCase() + "_rate";

    for(let h=2; h<county_data.length; h++) {
        let obj = {};
        // console.log(county_data[h]['name']);
        // console.log(county_data[h]['lat']);
        // console.log((county_data[h]['jan_rate'] * 100).toFixed(2));

        //calculate distance and store in array the selected values..
        // let dist = getDistance(selected_county_result[0], county_data[h]);
        if(selected_county_result[0]) {
            let dist = distance(selected_county_result[0].lat, selected_county_result[0].lng, county_data[h].lat, county_data[h].lng, "N");
            // let dist = closestLocation(selected_county_result[0], county_data[h]);
            // console.log(dist);
            // console.log("center - " + county_data[h]['name']);
            // console.log("distance - " + dist);

            //Get counties whithin 10 miles..
            // if(dist > 0 && dist <= (160.934) && countywise_result.length < 6) {
            if(dist > 0) {    
                obj['label'] = county_data[h]['name'];
                if(parseFloat(county_data[h][mshortname]) === 0)
                    obj['rate'] = "N/A";
                else 
                    obj['rate'] = (county_data[h][mshortname] * 100).toFixed(2) + "%";
                obj['distance'] = dist;
                countywise_result.push(obj);
            }
        }
    }

    //Sort by rate ascending..
    countywise_result.sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance));

    // console.log("countywise_result - " + countywise_result.length);
    // console.log(JSON.stringify(countywise_result));
    // console.log(countywise_result);
    return countywise_result.slice(0,6);
}

export { 
    filterByCounty,
    filterByMonth,
    filterByAge
};