import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from 'lodash';
import axios from 'axios';
import parse from 'html-react-parser';
import Autocomplete from "../components/Autocomplete";
import PerformerCard from "../components/PerformerCard";
import FrameFooter from "../components/FrameFooter";
import iframeResizer from "iframe-resizer/js/iframeResizer.contentWindow"

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data:{
        driving_data: [],
        age_data: [],
        counties: [],
        selected_county: "",
        selected_month: "",
        selected_age: "",
        jsonStaticData: {}
      },
      error_message: "",
      staticDataJson: {},
      month_arr: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
      max_age: 25,
      jsonAgeData: {} 
    }

    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleAgeChange = this.handleAgeChange.bind(this);
    this.formAgeJSON = this.formAgeJSON.bind(this);
    this.btnClickHandler = this.btnClickHandler.bind(this);
  }

  componentWillMount() {
    axios.get(process.env.PUBLIC_URL + "/data/driving_data.json")
      .then(res => {
        const data = res.data;
        
        //Age json processing after being converted to json..
        let driving_data_json = this.manageDrivingData(data);

        // let driving_data_json = formDrivingData();

        //Form counties array to display in sugestions list..
        let arr = driving_data_json.map(function(centre) {
          return centre.name
        });

        this.setState(prevState => ({
          data: {
              ...prevState.data,
              driving_data: driving_data_json, 
              counties: arr
          }
        }));
      });

    axios.get(process.env.PUBLIC_URL + "/data/age_data.json")
      .then(res => {
        const agedata = res.data;
        this.setState({ jsonAgeData: agedata });
        
        //Age json processing after being converted to json..
        this.formAgeJSON(agedata);
      });

    axios.get(process.env.PUBLIC_URL + "/data/static_content.json")
      .then(res => {
        const data = res.data;
        this.setState(prevState => ({
          data: {
              ...prevState.data,
              jsonStaticData: data
          }
        }));
      });

  }

  //Driving data json processing after being converted to json..
  manageDrivingData(jsonData) {
    let driving_data_json = jsonData;
    driving_data_json = driving_data_json.Sheet1;
    
    driving_data_json = driving_data_json.map(function(centre) {
      return {
          "name": centre['B'],
          "lat": centre['C'],
          "lng": centre['D'],
          "jan_rate": centre['G'],
          "feb_rate": centre['J'],
          "mar_rate": centre['M'],
          "apr_rate": centre['P'],
          "may_rate": centre['S'],
          "jun_rate": centre['V'],
          "jul_rate": centre['Y'],
          "aug_rate": centre['AB'],
          "sep_rate": centre['AE'],
          "oct_rate": centre['AH'],
          "nov_rate": centre['AK'],
          "dec_rate": centre['AN'],
          "total_rate": centre['AQ']
      };
    });

    //Remove first two objects as it contains header..
    driving_data_json.shift();
    driving_data_json.shift();

    // console.log("loaddata *************** ");
    // console.log(driving_data_json);
    
    return driving_data_json;
  }

  //Age json processing after being converted to json..
  formAgeJSON(jsonData) {
    // let age_data_json = JSON.parse(JSON.stringify(this.state.jsonAgeData));
    // console.log("jsonagedata");
    // console.log(jsonData);

    let age_data_json = jsonData;
    age_data_json = age_data_json.Sheet1;

    //Read header from 0th position 
    //and store in array of object..
    let age_key_arr = [];
    Object.keys(age_data_json[0])
      .forEach(function eachKey(key, index) {
        let j = {};
        j.key = age_data_json[0][key];
        age_key_arr.push(j);
      });
   
    // Remove first two objects as it contains header..
    age_data_json.shift();
    
    age_data_json = age_data_json.map(obj =>{ 
      let payload = {};
      let initialAscii = 65, cnter = 0;

      for(let k=initialAscii; k<(initialAscii+13); ++k) {
        let key = age_key_arr[cnter]['key'].toLowerCase().replace(/ /g,"-");
        payload[key] = obj[String.fromCharCode(k)];
        cnter++;
      }

      return payload;
   });

   //To remove + from age..
   age_data_json = JSON.stringify(age_data_json).replace(/\+/g,"");
   age_data_json = JSON.parse(age_data_json);
   
    // console.log("age_data_json");
    // console.log(JSON.stringify(age_data_json));

    //Set data..
    this.setState(prevState => ({
      data: {
          ...prevState.data,
          age_data: age_data_json
      }
    }));
  };

  handleMonthChange(event) {
    // console.log("selected month - " + event.target.value);

    let obj_data = Object.assign({}, this.state.data);
    obj_data.selected_month = event.target.value;
    this.setState({ data: obj_data });
  }

  handleAgeChange(event) {
    // console.log("selected month - " + event.target.value);

    let obj_data = Object.assign({}, this.state.data);
    obj_data.selected_age = event.target.value;
    this.setState({ data: obj_data });
  }

  onCountySelect(value) {
    // console.log(value + " ***  ");
    this.setState(prevState => ({
      data: {
          ...prevState.data,
          selected_county: value
      }
    }));
  }

  btnClickHandler(event) {
    // console.log("selected county - " + this.state.data.selected_county);
    // console.log("selected month - " + this.state.data.selected_month);
    // console.log("selected age - " + this.state.data.selected_age);

    if(this.state.data.selected_county === "") {
      event.preventDefault();
      this.setState({ error_message: "Please enter centre." });
      return false;
    }
    else if(this.state.data.selected_month === "") {
      event.preventDefault();
      this.setState({ error_message: "Please select month." });
      return false;
    }
    else if(this.state.data.selected_age === "") {
      event.preventDefault();
      this.setState({ error_message: "Please select age." });
      return false;
    }
   
    // this.setState({ error_message: "" });
    // return true;
  }

  render() {
    const { jsonStaticData } = this.state.data;

    return (
      <>
      <div className="row wrap-clas">
        <div className="ifr-sec">
          <section className="main-text">
            {jsonStaticData.frame_heading_txt &&
              <p>
                {parse(jsonStaticData.frame_heading_txt)}
              </p>
            }
          </section>

          {this.state.error_message && <div className="validation-message">{this.state.error_message}</div>}
          
            <fieldset>
              <Autocomplete suggestions={this.state.data.counties} onCountySelect={this.onCountySelect.bind(this)} />
            </fieldset>
            
            <fieldset>
              <div className="container select-container">
                <div className="dropdown-wrapper row">
                  <div className="month-of col-xs-6 col-md-6 col-sm-6">
                    <select 
                      defaultValue={this.state.data.selected_month} 
                      onChange={this.handleMonthChange} 
                      tabIndex="2" >
                        <option value="">Month of test</option>
                        {this.state.month_arr.map((x,y) => <option key={y} value={x}>{x}</option>)}
                    </select>
                  </div>
                  <div className="month-of col-xs-6 col-md-6 col-sm-6">
                    <select 
                      defaultValue={this.state.data.selected_age} 
                      onChange={this.handleAgeChange} 
                      tabIndex="3" >
                        <option value="">Age</option>
                        { _.range(17, this.state.max_age + 1).map(value => <option key={value} value={value}>{value}</option>) }
                        <option value="26">26+</option>
                    </select>
                  </div>
                </div>
              </div>
            </fieldset>
            
            <fieldset>
              <div className="button-sec">
              <Link to={{ pathname: '/results', data: { stateData: this.state.data } }} >
                    <button type="button" tabIndex="4" onClick={this.btnClickHandler}>
                    Calculate
                    </button>
                  </Link>
              </div>
            </fieldset>

            <div className="col-lg-12 static-centres-block">
              {jsonStaticData.performers &&
                <PerformerCard performerData={jsonStaticData.performers.best} />
              }
              {jsonStaticData.performers &&
                <PerformerCard performerData={jsonStaticData.performers.worst} />
              }
            </div>

            <FrameFooter jsonStaticData={jsonStaticData} /> 
          
        </div>
      </div>
      </>
    );
  }
}

export default Home;