import React, { Component } from "react";
import { Link } from "react-router-dom";

import FbShare from "../images/fb-share.png";
import TwitterShare from "../images/twitter-share.png";
import PassP from "../images/p-icon.png";
import RateCard from '../components/PassRateFiltered';
import CommonProgressBar from '../components/CommonProgressBar';
import FrameFooter from "../components/FrameFooter";
import { filterByCounty, filterByMonth, filterByAge } from "../selectors/CalculateRate";
import OutsideFrameContent from '../components/OutsideFrameContent';

class Results extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countydata: [],
      selectedcounty: [],
      selectedcountyrate: 0,
      monthdata: [],
      agedata: [],
      progressbarcount: 0
    };
  } 

  componentWillMount() {
    if (this.props.location && this.props.location.data ) {
      const { stateData } = this.props.location.data;
       
      //Call calculate method to display filtered data..
      let selected_county_result = stateData.driving_data.filter(obj => {
        return obj.name === stateData.selected_county;
      });
      
      //Get countywise data..
      let countywise_data = filterByCounty(stateData.driving_data, selected_county_result, stateData.selected_month);

      //Get Monthwise data..
      let monthwise_data = filterByMonth(stateData.selected_month, selected_county_result);
      
      //Get Age data to display..
      let agewise_data = filterByAge(stateData.selected_age, stateData.age_data);
      
      //Set monthwise data in state data..
      this.setState({
        countydata: countywise_data,
        selectedcounty: selected_county_result,
        selectedcountyrate: (selected_county_result[0][stateData.selected_month.substr(0, 3).toLowerCase() + "_rate"] * 100).toFixed(2),
        monthdata: monthwise_data,
        agedata: agewise_data
      });

    }

  }

  render() {
    const { stateData } = this.props.location.data;

    return (
      <> 
        { (this.state && stateData) && 
          <div className="ifr-sec col-lg-12 ifr-sec-results">
              <section className="main-text">
                <div className="main-bd-upper">
                  <h2><span>{stateData.selected_county}</span></h2>
                  {this.state.selectedcountyrate > 0 && <h5><span>Your chance of passing your driving test this {stateData.selected_month} in {stateData.selected_county} is...</span></h5> }
                  {this.state.selectedcountyrate > 0 && <div className="prob-progress"> <CommonProgressBar score={this.state.selectedcountyrate} counter={this.state.progressbarcount++} /></div> }
                  {/* No pass rate message display. */}
                  {this.state.selectedcountyrate <= 0 && <h5><span>Sorry, there's not enough data for this month. But you can still see how your age compares...</span></h5> }
                </div>
              </section>

              <div className="share-icons">
                <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsilly-pasteur-100450.netlify.com" target="_blannk" rel="noopener noreferrer"><img src={FbShare} className="share-icon-fb" alt="Share on Facebook"></img></a>
                <a href="https://twitter.com/intent/tweet?url=silly-pasteur-100450.netlify.com&text=https%3A%2F%2Fsilly-pasteur-100450.netlify.com" target="_blank" rel="noopener noreferrer"><img src={TwitterShare} className="share-icon-twitter" alt="Share on Twitter"></img></a>
              </div>

              <div className="pass-rate-section">
                <img src={PassP} alt="" ></img>
              </div>

              <div className="pass-rate-section1">
                <img src={PassP} alt="" ></img>
              </div>

              <section className="main-text">
                  <div className="row rate-box-wrapper">
                      <div className="col-xl-4 col-lg-4 col-md-4 rate-box">
                          <div className="rate-box-title">
                              <h6>Is age just a number? How does it affect your chances?</h6>
                          </div>
                          <div className="rate-box-content">
                            <div className="pass-rate-stat">
                                <h6>{this.state.agedata[0].age_label}</h6>
                                <CommonProgressBar score={this.state.agedata[0].age_rate} counter={this.state.progressbarcount++} ></CommonProgressBar>
                            </div>
                            <div className="pass-rate-stat">
                                <h6>{this.state.agedata[0].avg_label}</h6>
                                <CommonProgressBar score={this.state.agedata[0].avg_rate} counter={this.state.progressbarcount++} ></CommonProgressBar>
                            </div>
                          </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 rate-box">
                          <div className="rate-box-title">
                              <h6>How your test centre compares...</h6>
                          </div>
                          <div className="rate-box-content">
                              <table className="rate-box-tbl">
                                <tbody>
                                  {this.state.countydata.map((obj, index) => (
                                    <RateCard key={index} {...obj} />
                                  ))}
                                </tbody>
                              </table>
                          </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 rate-box">
                          <div className="rate-box-title">
                              <h6>What are your chances in your chosen month?</h6>
                          </div>
                          <div className="rate-box-content">
                              <table className="rate-box-tbl month-tbl">
                                <tbody>
                                    {this.state.monthdata.map((monthobj, index) => (
                                      <RateCard key={index} {...monthobj} />
                                    ))}
                                </tbody>
                              </table>
                          </div>
                      </div>
                  </div>
              </section>

              <section>
                  <div className="button-sec">
                    <Link to="/">
                      <button type="button" tabIndex="1">Start again</button>
                    </Link>
                  </div>
              </section>

              <FrameFooter jsonStaticData={stateData.jsonStaticData} ></FrameFooter>
              <hr ></hr>
              <OutsideFrameContent jsondata={stateData.jsonStaticData}></OutsideFrameContent>
              
              <div className="frame-caveat-txt">Terms and Conditions apply. This policy is only suitable for learner drivers aged 17 to 34. Price and cover is dependent on age and location.</div>
          </div>
        }   
      </>
    );

  }
}

export default Results;