import React from "react";
import parse from "html-react-parser";
import CommonProgressBar from "./CommonProgressBar";

let performerId = 0;

const PerformerCard = ({ performerData }) => (
    
    <div className="static-performer-block col-sm-6 "> 
        <div className="static-centre-text"> {parse(performerData.label)} </div>
        <div className="static-centre-name"> {performerData.center_info} </div>
        <CommonProgressBar score={performerData.rate} counter={performerId++} />
    </div>

);

export default PerformerCard;